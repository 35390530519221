export default {
  seoVN: true,
  home: {
    title: 'TCOM - Công ty công nghệ đa lĩnh vực hàng đầu Việt Nam',
    content_sub1:
      'Đội ngũ kỹ sư công nghệ trình độ cao, luôn luôn đổi mới. Quy trình phát triển phần mềm chuyên nghiệp,',
    content_sub2:
      ' quy trình kiểm soát chất lượng nghiêm ngặt. Chúng tôi đã, đang và sẽ là đối tác của các khách hàng lớn trên toàn thế giới.',
    hot_new: 'TIN TỨC NỔI BẬT',
    hot_new_description_sub1: 'Điểm qua những sự kiện, tin tức',
    hot_new_description_sub2: 'đáng chú ý nhất từ TCOM',
    fee_sub1: 'Chi phí',
    fee_sub2: 'tối ưu',
    quality_sub1: 'Chất lượng',
    quality_sub2: 'tốt nhất',
    implementation_sub1: 'Triển khai',
    implementation_sub2: 'nhanh nhất',
    my_service: 'CÁC DỊCH VỤ CỦA CHÚNG TÔI',
    my_service_sub1:
      'TCOM, công ty công nghệ và dịch vụ phần mềm hàng đầu, chúng tôi cung cấp các giải pháp',
    my_service_sub2:
      'phần mềm mạnh mẽ, có thể mở rộng, đáng tin cậy cho khách hàng ở mọi quy mô trên toàn cầu.',
    service_development: '& Phát triển phần mềm',
    service_development_tech: 'Phát triển Công nghệ tiên tiến',
    service_tech_development: 'Công nghệ tiên tiến',
    advise: 'Tư vấn Hệ thống IT',
    digital: 'Chuyển đổi số',
    advise_solution: 'Tư vấn giải pháp',
    enterprise: 'doanh nghiệp',
    travel_number_conversion: 'Chuyển đổi số du lịch',
    travel_number_education: 'Chuyển đổi số giáo dục',
    digital_manufacture: 'Chuyển đổi số trong sản xuất',
    smart_operation_center: 'Trung tâm điều hành thông minh',
    solutions: 'CÁC GIẢI PHÁP',
    solution_sub1:
      'TCOM đã và đang phát triển nhiều nền tảng, giải pháp công nghệ toàn diện,',
    solution_sub2:
      'thúc đẩy quá trình chuyển đổi số của doanh nghiệp, tổ chức.',
    solution_livestream: 'Giải pháp Livestream tương tác',
    solution_livestream_description:
      'Phát triển các ứng dụng cho phép người dùng livestream, mời khách cùng live, thêm khung hình, sticker, hiệu chỉnh hình ảnh, màu sắc. Người xem có thể chat trực tiếp, xin tham gia cùng livestream.',
    solution_service: 'Giải pháp dịch vụ',
    travel: 'du lịch',
    solution_service_description:
      'Phát triển ứng dụng du lịch trực tuyến, cho phép người dùng tìm kiếm địa điểm du lịch, khách sạn, nhà hàng, di chuyển, tour. Cho phép các đơn vị kinh doanh dịch vụ quảng bá, bán dịch vụ của mình.',
    solution_number: 'Giải pháp số hóa',
    vr: '360 VR',
    solution_number_description:
      'Phát triển nền tảng du lịch trực tuyến qua hình ảnh 360 VR, nền tảng hội chợ và triển lãm thực tế ảo.',
    solution_school: 'Giải pháp trường học thông minh',
    solution_school_description:
      'Phát triển giải pháp trường học thông minh, tập trung vào việc nâng cao chất lượng quản lý học sinh thông qua nhận diện, điểm danh, giám sát dựa trên công nghệ nhận diện khuôn mặt.',
    field: 'LĨNH VỰC',
    field_description:
      'Với đội ngũ trình độ cao, quy trình chuyên nghiệp, chúng tôi cung cấp dịch vụ tư vấn, phát triển và offshore cho nhiều ngành công nghiệp khác nhau trên toàn cầu.',
    news: 'Tin tức, báo chí',
    ecommerce: 'Thương mại điện tử',
    entertainment: 'Giải trí',
    media: 'Đa phương tiện',
    marketing: 'Marketing',
    quang_ba: 'quảng bá',
    image: 'hình ảnh',
    lear_stream: 'Học trực tuyến',
    hotel: 'Khách sạn,',
    restaurant: 'nhà hàng, du lịch',
    factory: 'Nhà máy',
    healthy: 'Chăm sóc sức khỏe,',
    medical: 'y tế',

    feature_project: 'DỰ ÁN NỔI BẬT',
    feature_project_description:
      'Một số dự án nổi bật mà chúng tôi đã triển khai thành công, làm khách hàng hài lòng.',
    member: 'Hệ thống Công ty thành viên',
    sub01_TSoft: 'Dịch vụ Outsourcing',
    sub02_TSoft: 'Phát triển phần mềm',
    sub03_TSoft: 'Trung tâm phát triển Offshore',
    sub01_TJapan: 'Dịch vụ Outsourcing tại thị trường Nhật Bản',
    sub02_TJapan: 'Phát triển quan hệ đối tác khách hàng',
    sub01_TEyefire: 'Trí tuệ nhân tạo, machine learning, deep learning',
    sub02_TEyefire: 'Computer vision',
    sub01_TSkylive: 'Livestream social commerce',
    sub02_TSkylive: 'Phát triển giải pháp Livestreaming',
    sub01_TGo: 'Cung cấp dịch vụ phần mềm',
    sub02_TGo: 'Phát triển đối tác, khách hàng thị trường quốc tế',
    read_more: 'Xem chi tiết',

    achievement: 'THÀNH TÍCH ĐẠT ĐƯỢC',
    member_description:
      'Luôn mở rộng quy mô, nâng cao trình độ đội ngũ, quy trình kiểm soát chất lượng nghiêm ngặt, TCOM đã và đang là đối tác tin cậy của các doanh nghiệp trong và ngoài nước.',
    project: 'Dự án',
    customer: 'Khách hàng',
    engineer: 'Kỹ sư',
    expert: 'Chuyên gia',
    customer_title: 'KHÁCH HÀNG',
    customer_description:
      'Chúng tôi có những khách hàng lớn từ khắp nơi trên thế giới. Với sự tận tâm, trách nhiệm, sáng tạo, chúng tôi mang đến cho khách hàng các sản phẩm chất lượng và sự hài lòng cao nhất.',
    recruit: 'TUYỂN DỤNG',
    recruit_description: 'Cơ hội phát triển sự nghiệp tại TCOM.',
    salary: 'Lương',
    location: 'Địa điểm',
    careers_description_sub1: 'TCOM luôn tìm kiếm những người tài năng,',
    careers_description_sub2:
      'nhiệt huyết, đáp ứng nhu cầu mở rộng quy mô nhân sự.',
    personnel: 'Nhân sự cho',
    position: 'vị trí',
    news_title: 'TIN TỨC',
    news_ask: 'TCOM có gì mới ?',
    Image: 'Hình ảnh',
    Video: 'Video',
    office: 'Văn Phòng TCOM',
    start: 'Xem Video',
  },
  layout: {
    about: 'Về chúng tôi',
    service: 'Dịch vụ',
    solution: 'Giải pháp',
    project: 'Dự án',
    job: 'Tuyển dụng',
    news: 'Tin tức',
    contact: 'Liên hệ',
    home: 'Trang chủ',
    introduce: 'Về chúng tôi',
    footer_introduce: 'GIỚI THIỆU',
    contact_description:
      'Hãy gửi cho chúng tôi những thắc mắc, góp ý hoặc đề nghị hợp tác của bạn.Chúng tôi sẽ phản hồi trong thời gian sớm nhất!',
    contact_submit: 'GỬI LIÊN HỆ HỢP TÁC',
    footer_title: 'TCOM',
    footer_service: 'CÁC DỊCH VỤ',

    office_head: 'Trụ sở chính',
    office_head_des:
      'Số 26B/92, Tổ 11, phường Hoàng Văn Thụ, Hoàng Mai, Hà Nội, Việt Nam',
    office_HN: 'Văn phòng Hà Nội',
    office_HN_des:
      'Tầng 3 tòa nhà văn phòng Fafim A, Số 19 Nguyễn Trãi, Phường Khương Trung, Quận Thanh Xuân, Hà Nội',
    office_HCM: 'Văn phòng Hồ Chí Minh',
    office_HCM_des:
      'Tầng 5, tòa nhà VTD số 17 Hồ Văn Huê, Phường 9, Quận Phú Nhuận, Thành Phố Hồ Chí Minh',
    office_Tokyo: 'Trụ sở Tokyo',
    office_Tokyo_des: '〒190-0023 東京都立川市柴崎町3-8-5',
  },
  service: {
    our_service_sub1: 'Các dịch vụ',
    our_service_sub2: 'của chúng tôi',
    strength_sub1: 'Thế mạnh',
    strength_sub2: 'của chúng tôi',
    language_code: 'Ngôn ngữ lập trình',
    tech: 'Công nghệ',
    step_active: 'Quy trình thực hiện',
    step_active_description:
      'Các bước trao đổi, làm việc đơn giản, rõ ràng, dễ dàng hợp tác',
    field: 'LĨNH VỰC',
    field_description: 'Các lĩnh vực mà chúng tôi cung cấp dịch vụ',

    process_title: 'Quy trình thực hiện',
    process_des:
      'Các bước trao đổi, làm việc đơn giản, rõ ràng, dễ dàng hợp tác',
  },
  project: {
    feature_project: 'Các dự án nổi bật',
    project_sub1: 'Một số dự án nổi bật mà chúng tôi đã triển khai thành công,',
    project_sub2: 'làm khách hàng hài lòng.',
    field: 'Lĩnh vực',
    language: 'Ngôn ngữ',
    no_result: 'Không có kết quả tìm kiếm',
    back_home: 'QUAY VỀ TRANG CHỦ',
    load_more: 'XEM THÊM',
    tech: 'Công Nghệ',
    scale: 'Quy mô',
    platform: 'Nền tảng',
  },
  news: {
    title_featured: 'Tin tức nổi bật',
    featured_description: `<p>Cùng điểm qua những tin tức đáng chú ý <br/>trong thời gian qua từ TCom nhé</p>`,
    featured_description_img_1: ` <p class='desc-img'>TCOM JAPAN tham gia triển lãm: <br/>Tuần lễ CNTT Nhật Bản mùa xuân lần thứ 31</p>`,
    featured_description_img_2: ` <p class='desc-img'>Back to Office: Vừa lạ vừa quen</p>`,
    featured_description_img_3: ` <p class='desc-img'>Sự kiện: Công nghệ tiêu biểu <br/> của năm 2022</p>`,
    title: 'Các tin tức mới nhất',
    new_img1: `<a href='#'><img className='img-fluid' src='/assets/images/news/img-featured-news.jpg' /></a>`,
    new_img2: `<a href='#'><img className='img-fluid' src='/assets/images/news/img-featured-news-2.jpg' /></a>`,
    new_img3: `<a href='#'><img className='img-fluid' src='/assets/images/news/img-featured-news-3.jpg' /></a>`,
  },
  about: {
    title_sub1: '<h1>TCOM Corporation</h1>',
    history_mount: 'Lịch sử hình thành',
    history_description:
      ' <p>TCOM là công ty công nghệ, thành lập năm 2012, bởi đội ngũ kỹ sư IT có hơn 15 năm kinh nghiệm phát triển sản phẩm, cũng như thực hiện các dự án công nghệ với các đối tác trong và ngoài nước. TCOM quy tụ những con người tài năng, nhiệt huyết, đam mê, sáng tạo, tốt nghiệp từ những trường đại học hàng đầu Việt Nam.</p>',
    mission: 'Sứ mệnh',
    mission_description:
      '<p>Tạo ra các sản phẩm và giải pháp công nghệ chất lượng, thúc đẩy quá trình chuyển đổi số toàn diện của xã hội.</p>',
    vision: 'Tầm nhìn',
    vision_description:
      'Trở thành tập đoàn công nghệ đa lĩnh vực hàng đầu Việt Nam.',
    roadmap_development: 'Lộ trình phát triển',
    staff: 'Nhân sự',
    staffplus: 'Quy mô 100+ nhân sự',
    staffplus2023: 'Hướng tới quy mô 200+ nhân sự',
    staff2021: 'Quy mô 85 nhân sự',
    staff2017: 'Nhân sự',
    debut: 'Ra mắt',
    Outsourcing: 'Mở mảng Outsourcing',
    skyStudioSkyMitSkyBot: 'Sky Studio, Sky Mit, Sky Bot',
    jun_2017: '06/2017',
    establish: 'Thành lập công ty con',
    establish2023: `TCOM Global`,
    establish2023_description:
      '- Phát triển khách hàng, đối tác thị trường tiếng Anh',
    establish_description:
      'TCOM Software, outsourcing service trong và ngoài nước',
    rice_wallet: 'Ra mắt Rice Wallet',
    rice_wallet_description: 'Ví quản lý tiền điện tử phi tập trung',
    Tcom_Japan: 'TCOMJAPAN',
    japan_outsource: 'Outsourcing service tại Nhật Bản',
    eyefire_description: 'Sản phẩm và giải pháp trí tuệ nhân tạo',
    japan_singapo: 'Tiếp cận thị trường nước ngoài Nhật Bản, Singapore',
    progress_end: 'Đẩy mạnh nghiên cứu công nghệ, hoàn thiện quy trình',
    japan_singapo_2018: 'Thị trường Nhật Bản, Singapore',
    company_member: 'HỆ THỐNG CÔNG TY THÀNH VIÊN',
    achievements: 'THÀNH TÍCH ĐẠT ĐƯỢC',
    achievement_description:
      'Luôn mở rộng quy mô, nâng cao trình độ đội ngũ, quy trình kiểm soát chất lượng nghiêm ngặt, TCOM đã và đang là đối tác tin cậy của các doanh nghiệp trong và ngoài nước.',
    customer: 'Khách hàng',
    experts: 'Chuyên gia',
    subTitle: `<p class='desc'>TCOM không ngừng lớn mạnh, chúng tôi đem đến cho bạn những giải pháp,<br/> công nghệ tiện ích cuộc sống trong thời đại 4.0</p>`,
    feedback: 'Nhận xét của khách hàng',
    customer_uppercase: 'KHÁCH HÀNG',
    customer_uppercase_description: `<p className='desc'>Chúng tôi có những khách hàng từ khắp nơi trên thế giới với sự tận tâm, trách nhiệm,<br /> sáng tạo chúng tôi đem đến đối tác những dịch vụ sản phẩm chất lượng và sự hài lòng cao nhất.</p>`,
    image: 'Hình ảnh',
    office: 'Văn Phòng TCOM',
    start: 'Xem Video',
    established: 'Thành lập',
    research:
      'Nghiên cứu công nghệ, phát triển sản phẩm LiveStreaming, WebRTC, AI, ML',
    menu_2021:
      'Nâng cao trình độ đội ngũ, nâng cấp quy trình quản lý chất lượng',
  },
  contact: {
    title: 'Để lại thông tin liên hệ',
    description: ` <p className='desc'>Gửi thông tin để được tư vấn, báo giá, hoặc liên hệ hợp tác kinh doanh.<br /> Vui lòng gửi email cho chúng tôi bất cứ lúc nào.<br /> Chúng tôi sẵn sàng lắng nghe và hỗ trợ quý khách.</p>`,
    tcom_vn: 'TCOM Việt Nam',
    name: 'Họ tên',
    phone: 'Số điện thoại',
    tieu_de: 'Tiêu đề',
    purpose: 'Mục đích',
    content: 'Nội dung',
    submit: 'GỬI THÔNG TIN',
    member: 'HỆ THỐNG THÀNH VIÊN CỦA CHÚNG TÔI',
    form_name: 'Họ tên',
    form_phone: 'Số điện thoại',
    form_subject: 'Tiêu đề',
    form_purpose: 'Mục đích liên hệ',
    form_quotation: 'Báo giá',
    form_request: 'Yêu cầu tư vấn',
    form_cooperation: 'Hợp tác',
    form_recruitment: 'Tuyển dụng',
    form_orther: 'Khác',
    form_message: 'Nội dung liên hệ',
    form_file: 'Đính kèm file',
  },
  solution: {
    feature_solution: 'Các giải pháp nổi bật',
    feature_solution_description: `<p className='desc'>TCOM đã và đang phát triển nhiều nền tảng, giải pháp công nghệ toàn diện,<br /> thúc đẩy quá trình chuyển đổi số của doanh nghiệp, tổ chức.</p>`,
    livestream_interactive: `<div className='name'>Giải pháp Livestream<br /> tương tác</div>`,
    livestream_sale: `<div className='name'>Giải pháp Livestream<br /> bán hàng</div>`,
    service_travel: ` <div className='name'>Giải pháp dịch vụ<br /> du lịch</div>`,
    digital: ` <div className='name'>Giải pháp số hóa<br /> 360 VR</div>`,
    school_smart: ` <div className='name'>Giải pháp trường học<br /> thông minh</div>`,
    camera: ` <div className='name'>Giải pháp<br /> AIOT Camera</div>`,
    building_smart: `  <div className='name'>Giải pháp<br /> tòa nhà thông minh</div>`,
    education: `<div className='name'>Giải pháp<br /> Online Education</div>`,
    telehealth: `<div className='name'>Giải pháp<br /> Telehealth</div>`,

    solution_1: `<h5>Giải pháp Livestream<br /> tương tác</h5>`,
    solution_1_description:
      'Phát triển các ứng dụng cho phép người dùng livestream, mời khách cùng live, thêm khung hình, sticker, hiệu chỉnh hình ảnh, màu sắc. Người xem có thể chat trực tiếp, xin tham gia cùng livestream, tặng quà, tặng điểm.',
    solution_2: `<h5>Giải pháp Livestream<br /> bán hàng</h5>`,
    solution_2_description:
      'Phát triển hệ thống online-shopping thông qua hình thức livestream, người bán livestream giới thiệu sản phẩm từ kho hàng, người xem tương tác, xem sản phẩm, và đặt hàng trực tiếp.',
    solution_3: `<h5>Giải pháp dịch vụ<br /> du lịch trực tuyến</h5>`,
    solution_3_description:
      'Phát triển các ứng dụng cho phép người dùng livestream, mời khách cùng live, thêm khung hình, sticker, hiệu chỉnh hình ảnh, màu sắc. Người xem có thể chat trực tiếp, xin tham gia cùng livestream, tặng quà, tặng điểm.',
    solution_4: `<h5>Giải pháp số hóa<br /> hình ảnh 360 VR</h5>`,
    solution_4_description:
      'Phát triển nền tảng du lịch trực tuyến qua hình ảnh 360 VR, nền tảng hội chợ và triển lãm thực tế ảo.',
    solution_5: `<h5>Giải pháp trường học<br /> thông minh</h5>`,
    solution_5_description:
      'Phát triển giải pháp trường học thông minh, tập trung vào việc nâng cao chất lượng quản lý học sinh thông qua nhận diện, điểm danh, giám sát dựa trên công nghệ nhận diện khuôn mặt. Đồng thời đảm bảo sự thông suốt.',
    solution_6: `<h5>Giải pháp tòa nhà<br /> thông minh</h5>`,
    solution_6_description:
      'Phát triển giải pháp toà nhà thông minh, cho phép tự động nhận diện cư dân thông qua khuôn mặt, để mở cửa, để sử dụng dịch vụ. Đồng thời cho phép cư dân có thể mở cửa từ xa cho khách của mình.',
    solution_7: `<h5>Giải pháp<br /> AIOT Camera</h5>`,
    solution_7_description:
      'Giải pháp biến camera thường thành AI camera, quản lý và lưu trữ camera tập trung, nền tảng cloud, cho phép xem và phân tích hình ảnh dựa trên công nghệ trí tuệ nhân tạo.',
    solution_8: `<h5>Giải pháp<br /> Online Education</h5>`,
    solution_8_description:
      'Phát triển các hệ thống học, dạy học trực tuyến thông qua video, thông qua lớp học tương tác thời gian thực.',
    solution_9: `<h5>Giải pháp<br /> Telehealth</h5>`,
    solution_9_description:
      'Phát triển Ứng dụng khám, tư vấn sức khoẻ từ xa theo yêu cầu, cho phép matching bác sĩ và bệnh nhân. Tư vấn thông qua video call, instant message.',
  },
  text_button: {
    read_more: 'XEM THÊM',
    keep_in_touch: 'KẾT NỐI VỚI CHÚNG TÔI',
  },
  slider: {
    solution: {
      title: 'Các giải pháp',
      desc: 'Chuyển đổi số',
      content:
        'Tư vấn và triển khai các giải pháp chuyển đổi số cho các tổ chức, doanh nghiệp trong hầu hết các lĩnh vực, giúp nâng cao năng suất và hiệu quả công việc',
    },
    ai: {
      title: 'Phát triển sản phẩm',
      desc: 'Trí tuệ nhân tạo',
      content:
        'Nghiên cứu và phát triển các sản phẩm, giải pháp dựa trên công nghệ trí tuệ nhân tạo - AI, tập trung vào mảng thị giác máy tính - computer vision, xử lý âm thanh - speed to text, text to speed, và AIoT Camera',
    },
    blockchain: {
      title: 'Công nghệ',
      desc: 'Blockchain',
      content:
        'Phát triển sản phẩm, triển khai dự án dựa trên công nghệ blockchain, AR/VR',
    },
    school: {
      title: 'Giải pháp',
      desc: 'Trường học thông minh',
      content:
        'Giải pháp tổng thể trong hệ sinh thái trường học, giúp nhà trường dễ dàng ứng dụng công nghệ vào quản lý, vận hành để tối ưu tất cả các hoạt động hằng ngày, đồng thời tạo ra kênh liên lạc thông suốt giữa nhà trường với phụ huynh học sinh.',
    },
    factory: {
      title: 'Giải pháp',
      desc: 'An toàn nơi làm việc',
      content:
        'TCOM mang tới giải pháp toàn diện để quản lý, giám sát an toàn trong nhà máy thông qua camera được tích hợp AI, giúp phát hiện và cảnh báo sớm các nguy cơ mất an toàn tiềm ẩn, từ đó có thể ngăn ngừa các sự cố có thể xảy ra.',
    },
    solution1: {
      title: 'Giải pháp',
      desc: 'Nhận diện khuôn mặt không nhầm lẫn',
      content:
        'Giải pháp nhận diện khuôn mặt đa nền tảng, chính xác, tốc độ nhanh, ứng dụng vào các bài toán chấm công, điểm danh, kiểm soát ra vào thông minh trong hầu hết các lĩnh vực.',
    },
    solution2: {
      title: 'Giải pháp',
      desc: 'An toàn nơi làm việc',
      content:
        'Giải pháp toàn diện để quản lý, giám sát an toàn trong nhà máy thông qua CCTV Camera được tích hợp các tính năng AI, giúp phát hiện và cảnh báo sớm các nguy cơ mất an toàn tiềm ẩn, từ đó có thể ngăn ngừa các sự cố có thể xảy ra.',
    },
  },
  offshore: {
    desc:
      'TCOM, trung tâm offshore, đối tác tin cậy của các khách hàng Nhật, Âu, Mỹ. Chúng tôi cung cấp dịch vụ offshore nhằm mang tới cho đối tác, khách hàng sản phẩm chất lượng với giá cả cạnh tranh nhất',
    note1: 'Chi phí hợp lý, tiết kiệm cho bạn 60%',
    note2: 'Có quản lý chất lượng người Nhật',
    note3: 'Đội ngũ kỹ sư trình độ cao, quy trình chuyên nghiệp',
    sv: 'Các dịch vụ offshore mà chúng tôi cung cấp',
    tech: 'Công nghệ và ngôn ngữ lập trình',
    corp: 'Các phương thức hợp tác',
    base: 'Là hình thức offshore mà hợp đồng được ký kết theo từng dự án.',
    base_desc:
      'TCOM sẽ quản lý team và mọi hoạt động trong quy trình phát triển dự án bao gồm các tài liệu mô tả từ khách hàng, kiến trúc kỹ thuật, thiết kế, lập trình, kiểm thử, triển khai sản phẩm.',
    dedicate:
      'Khách hàng xây dựng, quản lý, vận hành một team riêng theo yêu cầu, đặt tại TCOM.',
    dedicate_desc: 'Labor đảm bảo nhân lực ổn định cho dự án.',
    pattern_des1:
      'Khách hàng và BrSE của công ty làm việc và trao đổi trực tiếp',
    pattern_des2:
      'BrSE sang làm việc bên Khách hàng, và lập team phụ trách bên Việt Nam',

    question: 'VÌ SAO BẠN NÊN CHỌN TCOM ?',
    answer1: 'Giá cả cạnh tranh',
    answer2: 'Đội ngũ kĩ sư có trình độ chuyên môn cao',
    answer3: 'Quy trình quản lí chất lượng chuyên nghiệp',
    answer4: 'Hiểu sâu, làm chủ công nghệ, sẵn sàng tư vấn giải pháp',
    answer5: 'Có công ty con tại Nhật Bản',
    answer6: 'Có quản lí chất lượng người Nhật',

    step1: 'Trao đổi, lắng nghe yêu cầu',
    step2: 'Ký thoả thuận bảo mật thông tin',
    step3: 'Đề xuất, ước tính chi phí',
    step4: 'Ký kết hợp đồng',
    step5: 'Triển khai nghiệp vụ',
    step6: 'Bàn giao sản phẩm',
    corp_title: 'Cách thức hợp tác',
  },
  contact_a: 'LIÊN HỆ',
};
