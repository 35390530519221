export default {
  home: {
    title:
      'TCOM - Multidisciplinary technology company, your trusted IT partner!',
    content_sub1:
      'A team of highly qualified technology engineers, always innovating. Professional software development process',
    content_sub2:
      'strict quality control process. We have been, are and will be partners of major customers around the world',
    hot_new: 'Hot news',
    hot_new_description_sub1: `Some of the most notable events and news from TCOM`,
    hot_new_description_sub2: '',
    fee_sub1: 'Fee',
    fee_sub2: 'optimise',
    quality_sub1: 'Quality',
    quality_sub2: 'best',
    implementation_sub1: 'Implementation',
    implementation_sub2: 'fastest',
    my_service: 'Our Services',
    my_service_sub1:
      'TCOM is a leading technology and software services company; we supply customers of ',
    my_service_sub2:
      'all sizes throughout the world with software solutions that are powerful, scalable, and reliable',
    service_development: 'Software Development',
    service_development_tech: 'Cutting-edge Technologies',
    service_tech_development: 'Cutting-edge Technologies',
    advise: 'IT System Consulting',
    digital: 'Digital Transformation',
    advise_solution: 'Bussiness & Technology Consulting',
    enterprise: 'enterprise',
    travel_number_conversion: 'Digital transformation in Travel',
    travel_number_education: 'Digital transformation in Education',
    digital_manufacture: 'Digital transformation in Manufacture',
    smart_operation_center: 'Intelligent Operation Center',
    solutions: 'Outstanding Solutions',
    solution_sub1:
      'TCOM has been developing many comprehensive technology platforms and solutions, ',
    solution_sub2:
      'promoting the digital transformation process of businesses and organizations',
    solution_livestream: 'Interactive livestream solution',
    solution_livestream_description:
      'Develop applications that allow users to livestream, invite guests to live, add frames, stickers, edit images, and colors. Viewers can chat directly, please join the livestream.',
    solution_service: 'Online travel service solution',
    travel: 'travel',
    solution_service_description:
      'Developing an online travel application, allowing users to search for tourist attractions, hotels, restaurants, transportation, tours. Allow service business units to promote and sell their services.',
    solution_number: '360 VR image digitization solution',
    vr: '360 VR',
    solution_number_description:
      'Developing an online travel platform through 360 VR images, a virtual reality fair and exhibition platform.',
    solution_school: 'Smart School solution',
    solution_school_description:
      'Developing a smart school solution, focusing on improving the quality of student management through identification, attendance, and supervision based on face recognition technology.',
    field: 'Vertical Industries',
    field_description:
      'Thanks to our highly qualified staff and professional processes, we provide consulting, development and offshore services to various industries around the globe',
    news: 'Online News & Publishing',
    ecommerce: 'Retail & Ecommerce',
    entertainment: 'Entertainment',
    media: 'Media',
    marketing: 'Digital',
    quang_ba: 'Marketing Agencies',
    image: '',
    lear_stream: 'Online Learning & ',
    sub_lear_stream: 'E-Learning',
    hotel: 'Travel & ',
    restaurant: 'Tourism',
    factory: 'Manufacture',
    healthy: 'Healthcare',
    medical: '',

    feature_project: 'Outstanding Projects',
    feature_project_description:
      'Here are some of our noteworthy projects to the delight of our clients',
    member: 'TCOM CORPORATION',
    sub01_TSoft: 'Outsourcing service',
    sub02_TSoft: 'Software development',
    sub03_TSoft: 'Offshore development center',
    sub01_TJapan: 'Outsourcing service in Japan',
    sub02_TJapan: 'Partnership and customer development',
    sub01_TEyefire: 'Artificial intelligence, machine learning, deep learning',
    sub02_TEyefire: 'Computer vision',
    sub01_TSkylive: 'Livestream social commerce',
    sub02_TSkylive: 'Livestreaming solutions',
    sub01_TGo:
      'Provides IT resources, software services, and technology solutions to non-Japanese markets',
    sub02_TGo: '',
    read_more: 'Read more',

    achievement: 'Achievements',
    member_description:
      'TCOM has been and continues to be a reliable business partner for both domestic and international companies thanks to its commitment to scaling up operations and investing in people',
    project: 'projects',
    customer: 'customers',
    engineer: 'engineers',
    expert: 'experts',

    customer_title: 'Our Customers',
    customer_description:
      'Worldwide, we work with major corporations. Inspiring trust and delight among our clientele is our top priority; to this end, we apply ourselves with dedication, responsibility, and creativity',
    recruit: 'RECRUIT',
    recruit_description: 'Career development at TCOM.',
    salary: 'Salary',
    location: 'Location',
    careers_description_sub1: 'TCOM is always looking for talented people,',
    careers_description_sub2:
      'enthusiasm, meeting the needs of expanding the scale of personnel.',
    personnel: 'Personnel for',
    position: 'position',

    news_title: 'News',
    news_ask: `What's new in TCOM?`,
    Image: 'Image',
    Video: 'Video',
    office: `TCOM's Office`,
    start: 'Watch now',
  },
  layout: {
    about: 'About us',
    service: 'Our Services',
    solution: 'Our Solutions',
    project: 'Projects',
    job: 'Careers',
    news: 'News',
    contact: 'Contact Us',
    home: 'Home',
    introduce: 'About us',
    footer_introduce: 'INTRODUCE',
    contact_description:
      'Send information for advice, quotation, or business cooperation contact. Please email us at any time. We are ready to listen and support you.',
    contact_submit: 'SEND CONTACT CORPORATION',
    footer_title: 'TCOM',
    footer_service: 'SERVICES',

    office_head: 'Head Office',
    office_head_des:
      'No 26B/92 Group 11, Hoang Van Thu Ward, Hoang Mai District, Ha Noi City, Viet Nam',
    office_HN: 'Ha Noi Office',
    office_HN_des:
      '3rd floor, Fafim A Building, 19 Nguyen Trai Street Thanh Xuan District, Ha Noi City, Viet Nam',
    office_HCM: 'Ho Chi Minh Office',
    office_HCM_des:
      '5F, VTD Building 17 Ho Van Khue Street, Ward 9, Phu Nhuan District, Ho Chi Minh City, Viet Nam',
    office_Tokyo: 'Tokyo Office',
    office_Tokyo_des:
      '504 Tachikawa NX Building 5F, 3-8-5 Shibasakicho, Tachikawa, Tokyo, Japan 190-0023',
  },
  service: {
    our_service_sub1: 'Our Services',
    our_service_sub2: '',
    strength_sub1: 'Our',
    strength_sub2: 'Strengths',
    language_code: 'Language Code',
    tech: 'Technology',
    step_active: 'Step Action',
    step_active_description:
      'The steps to exchange and work are simple, clear, and easy to cooperate',
    field: 'FIELDS',
    field_description: 'Aspects in which we provide services',

    process_title: 'Working process',
    process_des: 'Simple, clear, and easy-to-collaborate working steps',
  },
  project: {
    feature_project: 'Feature Projects',
    project_sub1: 'We provide powerful software solutions,',
    project_sub2: 'scalable and reliable for customers globally.',
    field: 'Fields',
    language: 'Languages',
    no_result: 'No Result',
    back_home: 'Back To Home',
    load_more: 'LOAD MORE',
    tech: 'Technology',
    scale: 'Scale',
    platform: 'Platform',
  },
  news: {
    title_featured: 'Featured news',
    featured_description: `<p>Some of the most notable events and news from TCOM</p>`,
    featured_description_img_1: ` <p class='desc-img'>TCOM JAPAN participates in the exhibition:<br/>31st Japan IT Week Spring </p>`,
    featured_description_img_2: ` <p class='desc-img'>Back to Office: Both strange and familiar</p>`,
    featured_description_img_3: `  <p class='desc-img'>Event: Typical Technology <br/> of the year 2022</p>`,
    title: 'News',
    new_img1: `<a className href='#'><img className='img-fluid' src='/assets/images/news/img-featured-news.jpg' /></a>`,
    new_img2: `<a href='#'><img className='img-fluid' src='/assets/images/news/img-featured-news-2.jpg' /></a>`,
    new_img3: `<a href='#'><img className='img-fluid' src='/assets/images/news/img-featured-news-3.jpg' /></a>`,
  },
  about: {
    // title_sub1: `<h1>We are TCOM<br /> Multi-field technology company,<br /> Your trusted IT partner</h1>`,
    title_sub1: '<h1>TCOM Corporation</h1>',

    history_mount: 'History of formation',
    history_description:
      ' <p>TCOM was founded in 2012 in Vietnam, by a group of IT experts with more than 15 years of experience in product development and the implementation of technology projects with domestic and foreign partners founded a technology firm called TCOM. TCOM gathers talented, passionate and creative people, who graduated from leading universities in Vietnam</p>',
    mission: 'Mission',
    mission_description:
      '<p>Create quality technology products and solutions, promote the comprehensive digital transformation of society</p>',
    vision: 'Vision',
    vision_description:
      'Become the leading multi-field technology corporation in Vietnam',
    roadmap_development: 'Development Roadmap',
    // staffplus: 'towards a scale of 100 employees',
    staff: 'Staff',
    staffplus: 'Scale of 100+ employees',
    staffplus2023: 'Towards a scale of 200+ employees',
    staff2021: 'Scale of 85 employees',
    staff2017: 'Staff',
    establish: 'Establishment of a subsidiary',
    establish2023: `TCOM Global, outsourcing service at home and abroad`,
    establish2023_description: '',
    establish_description:
      'TCOM Software, outsourcing service at home and abroad',
    rice_wallet: 'Launch of Rice Wallet',
    rice_wallet_description: 'A decentralized cryptocurrency wallet',
    Tcom_Japan: 'TCOM JAPAN',
    japan_outsource: 'Outsourcing service in Japan',
    eyefire_description: 'Artificial intelligence products and solutions',
    japan_singapo: 'Access to foreign markets Japan, Singapore',
    progress_end: 'Promote technology research, process improvement',
    japan_singapo_2018: 'Market Japan, Singapore',
    company_member: 'TCOM Corporation',
    achievements: 'ACHIEVEMENTS',
    achievement_description:
      'Always expanding the scale, improving the team level, strict quality control process, TCOM has been and is a reliable partner of domestic and foreign enterprises.',
    customer: 'Customer',
    experts: 'Experts',
    subTitle: `<p class='desc'>TCOM is constantly growing, we bring you solutions,<br/> technology that is convenient for life in the 4.0 era.</p>`,
    feedback: `Customer's feedback`,
    customer_uppercase: 'CUSTOMER',
    customer_uppercase_description: `<p className='desc'>We have customers from all over the world with dedication, responsibility,<br /> Creatively, we bring our partners the highest quality product services and satisfaction.</p>`,
    image: 'Image',
    office: 'TCOM Office',
    start: 'Watch now',
    established: 'Established',
    research:
      'Technology research, development LiveStreaming products, WebRTC, AI, ML',
    menu_2021:
      'Improve the level of the team, upgrade the quality management process',
  },
  contact: {
    title: 'Contact Information',
    description: ` <p className='desc'>Just shoot over your details if you need some advice, a price quote, or to set up some sort of business partnership. You can contact us whenever you like by sending an email. We're here to hear you out and give you our full backing</p>`,
    tcom_vn: 'TCOM Viet Nam',
    name: 'Name',
    phone: 'Mobile',
    tieu_de: 'Title',
    purpose: 'Purpose',
    content: 'Content',
    submit: 'SUBMIT',
    member: 'OUR MEMBERS',
    form_name: 'Fullname',
    form_phone: 'Phone',
    form_subject: 'Subject',
    form_purpose: 'How can we help',
    form_quotation: 'Quotation',
    form_request: 'Request a consultation',
    form_cooperation: 'Cooperation',
    form_recruitment: 'Recruitment',
    form_orther: 'orther',
    form_message: 'Your message',
    form_file: 'File attachment',
  },
  solution: {
    feature_solution: 'Feature Solutions',
    feature_solution_description: `<p className='desc'>TCOM has been developing many comprehensive technology platforms and solutions,<br /> promote the process of digital transformation of businesses and organizations.</p>`,

    livestream_interactive: `<div className='name'>Interactive Livestream<br /> Solution</div>`,
    livestream_sale: `<div className='name'>Livestream Social<br /> Commerce solution</div>`,
    service_travel: ` <div className='name'>Online travel <br />service solution</div>`,
    digital: ` <div className='name'>360 VR image <br />digitization solution</div>`,
    school_smart: ` <div className='name'>Smart school <br />solutions</div>`,
    camera: ` <div className='name'>AIOT Camera <br /> solution</div>`,
    building_smart: `  <div className='name'>Smart building<br /> solution</div>`,
    education: `<div className='name'>Online Education <br />solution</div>`,
    telehealth: `<div className='name'>Telehealth <br />solution</div>`,

    solution_1: `<h5>Interactive Livestream<br /> Solution</h5>`,
    solution_1_description:
      'We develop programs that enable users to livestream, invite visitors to watch live, alter photos and colors, add frames and stickers, and more. Viewers have the ability to engage in direct conversation, participate in the broadcast, send gifts, and rack up point totals',
    solution_2: `<h5>Livestream Social <br />Commerce solution</h5>`,
    solution_2_description:
      'We offer a livestream-based online shopping platform, where merchants may broadcast livestreams to introduce products directly from the warehouse, viewers can engage in conversation, browse available products, and place orders in real time',
    solution_3: `<h5>Online travel<br /> service solution</h5>`,
    solution_3_description:
      'We develop an online travel application that will enable users to search for information on tourist attractions and tourist destinations, make reservations at hotels and restaurants, purchase tickets for travel, and participate in experience tours. Also, service-based firms are able to market and sell their offerings with the assistance of this solution.',
    solution_4: `<h5>360 VR image <br />digitization solution</h5>`,
    solution_4_description:
      'We provide an online platform for tourism that uses 360 VR photos, as well as a platform for virtual reality exhibitions and fairs in 360 VR',
    solution_5: `<h5>Smart school <br />solution</h5>`,
    solution_5_description:
      'This is a complete solution that can be implemented in the school ecosystem. It enables schools to easily implement technology in management and operation in order to maximize the efficiency of all daily activities while simultaneously establishing a clear communication channel between the school and the parents of students',
    solution_6: `<h5>Smart building <br />solution</h5>`,
    solution_6_description:
      'We offer solutions for smart buildings that enable automatic face recognition of residents in order to open doors and access services, as well as the ability for residents to remotely unlock doors for visitors to their homes',
    solution_7: `<h5>AIOT Camera <br />solution</h5>`,
    solution_7_description:
      'This is a solution that can transform a regular camera into an AI camera, as well as a centralized camera administration and storage system and a cloud platform that enables viewing and analysis of images using artificial intelligence technology',
    solution_8: `<h5>Online Education <br />solution</h5>`,
    solution_8_description:
      'We provide learning systems, including instruction over the internet via video and in real-time through interactive classrooms',
    solution_9: `<h5>Telehealth <br /> solution</h5>`,
    solution_9_description:
      'We provide a remote medical examination and consultation application that can be used on demand, hence facilitating the pairing of medical professionals with patients. Video calls and instant messaging are both viable options for conducting consultations',
  },
  text_button: {
    read_more: 'READ MORE',
    keep_in_touch: 'CONNECT WITH US',
  },
  slider: {
    solution: {
      title: 'Solutions',
      desc: 'Digital transformation',
      content:
        'Consulting and implementing digital transformation solutions for organizations and businesses in most fields, helping to improve productivity and work efficiency',
    },
    ai: {
      title: 'Product development',
      desc: 'Artificial intelligence',
      content:
        'Research and development of products and solutions based on artificial intelligence (AI technology), focusing on computer vision, speed to text, text to speed, and AIoT Camera',
    },
    blockchain: {
      title: 'Technology',
      desc: 'Blockchain',
      content:
        'Product development, project implementation based on blockchain technology, AR/VR',
    },
    school: {
      title: 'Solutions',
      desc: 'Smart School',
      content:
        "Comprehensive and advanced solution for educational organizations, helping schools easily apply technology in management and operations to optimize all day-to-day activities, while creating a smooth communication channel, between schools and students' parents.",
    },
    factory: {
      title: 'Solutions',
      desc: 'Workplace Safety',
      content:
        'TCOM empowers safety teams with real-time insights and transforms safety management by replacing lagging indicators with leading indicators,enabling early identification of potential hazards and preventing incidents before they occur.',
    },
    solution1: {
      title: 'Solutions',
      desc: 'Unmistakable face recognition',
      content:
        'Multi-platform, accurate, fast-speed facial recognition solution, applied to timekeeping, attendance, and smart access control problems in most fields.',
    },
    solution2: {
      title: 'Solutions',
      desc: 'Workplace Safety',
      content:
        'Comprehensive solution for managing and monitoring safety in factories through CCTV Cameras integrated with AI features, helping to detect and warn early of potential safety risks, thereby preventing incidents. problems may occur.',
    },
  },
  offshore: {
    desc:
      'TCOM, an offshore center, is a dependable partner for Japanese, US, Europe clients. We provide our partners and consumers with a variety of high-quality products at the most competitive pricing possible through the provision of offshore services',
    note1: 'Reasonable cost, save you 60% cost',
    note2: 'Japanese quality managers',
    note3: 'Highly qualified engineers with professional procedures',
    sv: 'Offshore services that we provide',
    tech: 'Technologies',
    corp: 'Methods of cooperation',
    base:
      'This is an offshore form where contracts are signed for each project.',
    base_desc:
      'TCOM will manage the team and all activities in the project development process including description documents from customers, technical architecture, design, programming, testing, and product deployment.',
    dedicate:
      'Customers will build, manage and operate a separate team on demand, located at TCOM.',
    dedicate_desc: 'Labor ensures stable human resources for the project.',
    pattern_des1:
      'Customers communicate directly with the team through BrSE at TCOM',
    pattern_des2:
      "TCOM's BrSE works at the customer's company, and the implementation team is established at TCOM",

    question: 'Why should you choose Tcom ?',
    answer1: 'Most competitive prices',
    answer2: 'High-level skilled engineer team',
    answer3: 'Professional quality management process',
    answer4:
      'Deep understanding and mastery of technologies, ready to consult on solutions',
    answer5: 'Subsidiary company in Japan',
    answer6: 'Quality management overseen by Japanese personnel',

    step1: 'Exchange and listen to requests',
    step2: 'NDA signing',
    step3: 'Proposal, cost estimate',
    step4: 'Contract signing',
    step5: 'Development',
    step6: 'Product delivery',
    corp_title: 'Cooperation process',
  },

  contact_a: 'CONTACT',
};
